import { type FC, type PropsWithChildren, useEffect, useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { Breadcrumb, Button } from 'flowbite-react';
import { Helmet } from 'react-helmet';
import { HiHome } from 'react-icons/hi';
import { toast } from 'react-toastify';
import type { Analytic } from 'types/analytic';
import { deleteAnalyticSubscription } from 'domains/api/deleteAnalyticSubscription';
import { usePageTracking } from 'utils/ga';
import AnalyticSubscriptionButton from 'components/molecules/Analytics/AnalyticSubscriptionButton';

const Plan: FC<PropsWithChildren> = () => {
  usePageTracking(); // ページビュー計測
  const analytic = useLoaderData() as Analytic;
  const { analyticId } = useParams();
  const [isExpires, setIsExpires] = useState<boolean>(true);

  useEffect(() => {
    if (analytic.expiresAt) {
      const expiresDate = new Date(`${analytic.expiresAt}T23:59:59`);
      const now = new Date();
      if (expiresDate > now) {
        setIsExpires(false);
      }
    } else {
      // NOTE: expiresAtがnullの場合、どのプランにも加入していないということなので true にする
      setIsExpires(true);
    }
  }, []);

  const deleteSubscription = async () => {
    const confirm = window.confirm('ベーシックプランを解約しますか？');
    if (confirm) {
      const response = await deleteAnalyticSubscription(analytic.id);
      if (!response.ok) {
        toast.error(
          'エラーが発生しました。しばらく時間をおいてからやり直してください。',
        );

        return;
      }
    }
    window.location.reload();
  };

  return (
    <>
      <Helmet>
        <title>ご利用プラン: Growth Support</title>
      </Helmet>
      <div className="border-b py-3 pl-2 flex justify-between">
        <Breadcrumb aria-label="Default breadcrumb example">
          <Breadcrumb.Item href={`/analytics/${analyticId}`} icon={HiHome}>
            {analytic.name}
          </Breadcrumb.Item>
          <Breadcrumb.Item>ご利用プラン</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="border-b pt-5 pb-2 pl-2 flex justify-between">
        <h1 className="text-xl leading-none tracking-tight text-gray-900 md:text-xl dark:text-white">
          ご利用プラン{' '}
          {(analytic.plan === 'basic' && '(ベーシックプラン)') ||
            (analytic.plan === 'free' && '(フリープラン)')}
        </h1>
      </div>
      <div className="my-4 mx-3">
        {!isExpires && (
          <div className="mb-5">有効期限: {analytic.expiresAt}</div>
        )}
        {isExpires && (
          <>
            <p>
              現在 Growth Support
              はベータ版のため、フリープランにで全機能をご利用いただけます
            </p>
            {/* <AnalyticSubscriptionButton analyticId={analytic.id} /> */}
            {/* <div className="text-base"> */}
            {/*  ※ */}
            {/*  すでにベーシックプランに申し込まれている場合、反映まで時間がかかる場合がありますので、しばらくお待ちください。 */}
            {/* </div> */}
          </>
        )}
        {analytic.plan === 'basic' && (
          <>
            <Button color="light" onClick={deleteSubscription}>
              ベーシックプランを解約する
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default Plan;
